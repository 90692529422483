import { useLazyQuery, useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import React, { useContext, useEffect, useState } from "react";
import { CANCEL_TASK, CREATE_A_TASK, EDIT_A_TASK, GET_A_TASK, GET_DRIVER_REQUESTS, UPDATE_TASK } from "../../components/GraphQL";
import { Button, Checkbox, Dropdown, Icon, Input, Modal, Pagination, Popup, Table } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import * as firebase from 'firebase';
import moment from "moment";
import kuwaitAreas from "../../constants/kuwaitAreas";
import { Context as AppContext } from "../../context/appSettings";
import GoogleMapPicker from './GoogleMapPicker';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const RequestDriver = () => {
  const {isInCollection} = useContext(AppContext);
  const { t } = useTranslation('requestDriver');
  const [createTask] = useMutation(CREATE_A_TASK);
  const [cancelTask] = useMutation(CANCEL_TASK);
  const [editATask] = useMutation(EDIT_A_TASK);

  const currentUser = firebase.auth().currentUser;
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [requests, setRequests] = useState([]);
  const [date, setDate] = useState(new Date());
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const inactiveStates = ['cancelled', 'declined', 'delivered', 'completed','rejected'];
  const [errors, setErrors] = useState({ customerNumber: '', customerName: '', deliveryZone: '' });
  const unresolvedErrors = Object.values(errors).some((error) => error !== '');
  const [open, setOpen] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [gov, setGov] = useState('');
  const [area, setArea] = useState('');
  const [refresh, setRefresh] = useState(new Date());

  const AREAS = kuwaitAreas;
  const govDropdownOptions = Object.keys(AREAS).map((key) => ({
    key,
    text: key,
    value: key,
  }));

  const [getDriverRequests] = useLazyQuery(GET_DRIVER_REQUESTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRequests(data.getTasks.tasks);
      setLimit(data.getTasks.limit);
      setTotalPages(Math.ceil(data.getTasks.total/data.getTasks.limit));
    },
    onError: (err) => {
      console.log("Store error", err);
    },
  });

  useEffect(() => {
    getDriverRequests({
      variables: {
       filter: {
        sellerId: currentUser.uid,
       }
      }
    });
  }, [refresh])

  const onTaskUpdated = (newTask) => {
    setRequests((prevRequests) => {
      const updatedRequests = prevRequests.map((request) => {
        if (request.id === newTask.id) {
          return { ...request, ...newTask };
        }
        return request;
      });

      return updatedRequests;
    });
  };

  useSubscription(UPDATE_TASK, {
    variables: {
      sellerId: currentUser.uid,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const newTask = subscriptionData.data.updateTask;
      onTaskUpdated(newTask);
    },
  });

  useEffect(() => {
    getDriverRequests({
      variables: {
        filter: {
          sellerId: currentUser.uid,
          ...(showOnlyActive ? { status: { $nin: inactiveStates } } : {}),
        },
        limit,
        page,
      },
    });
  }, [currentUser.uid, date, page, showOnlyActive]);

  const { refetch: fetchCancelled } = useQuery(GET_A_TASK, {
    variables: { id: null },
    skip: true,
  });

  const [request, setRequest] = useState({
    sellerId: currentUser ? currentUser.uid : '',
    sellerName: currentUser ? currentUser.displayName: '',
    customerName: '',
    customerNumber: '',
    deliveryZone: '',
    additionalAddressDetail: '',
    collectCash: false,
    totalAmount: '0',
    specialNotes: '',
    customPickup: false,
    pickupName:'',
    pickupNumber: '',
    pickupNotes: '',
    pickupLocation: {
      latitude: 29.3759,
      longitude: 47.9774 ,
    },
  });
  const exportTableToExcel = () => {
    const flattenedRequests = requests.map(request => ({
      ...request,
      latitude: request.pickupLocation ? request.pickupLocation.latitude : null,
      longitude: request.pickupLocation ? request.pickupLocation.longitude : null
    }));
    const ws = XLSX.utils.json_to_sheet(flattenedRequests);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DriverRequests');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, 'DriverRequests.xlsx');
  };

  const [differentLocation, setDifferentLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Function to handle the checkbox change
  const handleDifferentLocationChange = () => {
    setDifferentLocation(!differentLocation);
  };

  // Function to handle location update from GoogleMapPicker
  const handleLocationUpdate = (location) => {
    setSelectedLocation(location);
  };
  const handleLatitudeChange = (value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      pickupLocation: {
        ...prevRequest.pickupLocation,
        latitude: value,
      },
    }));
  };

  const handleLongitudeChange = (value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      pickupLocation: {
        ...prevRequest.pickupLocation,
        longitude: value,
      },
    }));
  };

  const handleInputChange = (e, { name, value }) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      [name]: value,
    }));
};

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleToggleChange = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      collectCash: !prevRequest.collectCash,
    }));
  };

  const onCancelTask = async (id) => {
    setCancelButtonDisabled(true);
    try {
      await cancelTask({
        variables: {
          id,
        },
      });
      const { data } = await fetchCancelled({ id });
      if (data && data.getTask) {
        const cancelledTask = data.getTask;
       const updatedRequests =requests.map(request => {
            if (request.id === cancelledTask.id) {
              return cancelledTask;
            }
            return request;
          });

        setRequests(updatedRequests);
      }
    } catch (error) {
      window.alert('failed to cancel');
    }
    setCancelButtonDisabled(false);
  }

  const cleanup = () => {
    setRequest({
      ...request,
      customerName: '',
      customerNumber: '',
      deliveryZone: '',
      additionalAddressDetail: '',
      collectCash: false,
      totalAmount: '0',
      specialNotes: '',
      customPickup: false,
      pickupName:'',
      pickupNumber: '',
      pickupNotes: '',
      pickupLocation: {
        latitude: 29.3759,
        longitude: 47.9774 ,
      },
    });
    setEditTask(null);
    setArea('');
    setGov('');
  };

  const onCancel = () => {
    cleanup();
    setOpen(false)
  };

  const onSend = async () => {
    const valid = validateForm();
    if (valid) {
      setSendButtonDisabled(true);
      if (!!editTask) {
        try{
          const edited = {
            sellerId: currentUser ? currentUser.uid : '',
            sellerName: currentUser ? currentUser.displayName : '',
            customerName: request.customerName,
            customerNumber: request.customerNumber,
            deliveryZone: request.deliveryZone,
            additionalAddressDetail: request.additionalAddressDetail,
            collectCash: request.collectCash,
            totalAmount: request.totalAmount,
            specialNotes: request.specialNotes,
            customPickup: request.customPickup,
            pickupLocation: request.pickupLocation,
          };
          await editATask({
            variables: {
              updatedTask: edited,
              id: editTask,
            },
          });
          setDate(new Date());
          cleanup();
        } catch (error) {
          console.log(error);
          window.alert(
            'Failed to edit. Check the fields and your internet connection.',
          );
        }
      } else {
        try {
          const data = await createTask({
            variables: {
              taskInput: request,
            },
          });
          setDate(new Date());
          cleanup();
        } catch (error) {
          console.log(error);
          window.alert(
            'Failed to send your request. Check the fields and your internet connection.',
          );
        }
      }
      cleanup();
      setOpen(false);
      setSendButtonDisabled(false);
      setEditTask(null);
    }
  }



  const inputFields = [
    {
      label: t('requestDriver:CustomerName'),
      name: 'customerName',
      value: request.customerName,
      required: true,
    },
    {
      label: t('requestDriver:CustomerNumber'),
      name: 'customerNumber',
      value: request.customerNumber,
      required: true,
    },
    {
      label: t('requestDriver:specialNotes'),
      name: 'specialNotes',
      value: request.specialNotes,
      required: false,
    },
  ];

  const validateForm = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      deliveryZone: `${gov}${gov && area ? ' - ' : ''}${area}`,
    }));
    const errors = {
      customerNumber: '',
      customerName: '',
      deliveryZone: '',
    };
    let isValid = true;
    if (request.customerNumber.length === 0) {
      errors.customerNumber = 'Customer number is required';
      isValid = false;
    }
    else if (request.customerNumber.length !== 8) {
      errors.customerNumber = 'Customer number must be 8 digits';
      isValid = false;
    }
    // Check if customerNumber contains only numbers
    else if (!/^\d+$/.test(request.customerNumber)) {
      errors.customerNumber = 'Customer number must contain only digits';
      isValid = false;
    }
    if (!request.customerName) {
      errors.customerName = 'Customer name is required';
      isValid = false;
    }
    if (!request.deliveryZone) {
      errors.deliveryZone = 'Delivery Zone is required';
      isValid = false;
    }
    if (!area) {
      errors.deliveryZone = 'The area must be selected';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const RequestRow = ({ request }) => {
    const [expanded, setExpanded] = useState(false);

    const handleRowClick = () => {
      setExpanded(!expanded);
    };

    return (
      <>
        <Table.Row onClick={handleRowClick} key={request.id}>
          <Table.Cell>{request.orderNumber}</Table.Cell>
          <Table.Cell>{request.customerName}</Table.Cell>
          <Table.Cell>{request.customerNumber}</Table.Cell>
          <Table.Cell>{request.deliveryZone}</Table.Cell>
          <Table.Cell>{request.specialNotes}</Table.Cell>
          <Table.Cell>{request.additionalAddressDetail}</Table.Cell>
          <Table.Cell>{request.collectCash}</Table.Cell>
          <Table.Cell>{request.totalAmount}</Table.Cell>
          <Table.Cell>
            {request.status === 'pending' ? (
              <span style={{color: 'orange'}}>
                <i className="circle icon"></i> Pending
              </span>
                        ) : request.status === 'requested' ? (
                            <span style={{color: 'blue'}}>
                <i className="circle icon"></i> Requested
              </span>
            ) : request.status === 'accepted' ? (
              <span style={{color: 'green'}}>
                <i className="circle icon"></i> Accepted
              </span>
            ) : request.status === 'pickedup' ? (
              <span style={{color: 'purple'}}>
                <i className="circle icon"></i> Picked Up
              </span>
            ) : request.status === 'enroute' ? (
              <span style={{color: 'teal'}}>
                <i className="circle icon"></i> En Route
              </span>
            ) : request.status === 'delivered' ? (
              <span style={{color: 'black'}}>
                <i className="circle icon"></i> Delivered
              </span>
            ) : request.status === 'cancelled' ? (
              <span style={{color: 'black'}}>
                <i className="circle icon"></i> Cancelled
              </span>
            ) : request.status === 'declined' ? (
              <span style={{color: 'black'}}>
                <i className="circle icon"></i> Declined
              </span>
            ) : request.status === 'completed' ? (
              <span style={{color: 'black'}}>
                <i className="circle icon"></i> Completed
              </span>
            ) : (
              <span>N/A</span>
            )}
          </Table.Cell>
          <Table.Cell>{request.deliveryTime}</Table.Cell>
          <Table.Cell>{request.completedTime}</Table.Cell>
          <Table.Cell>
            {request.status === 'pending' && (
              <Button
                onClick={() => onCancelTask(request.id)}
                disabled={cancelButtonDisabled}>
                {t('requestDriver:cancel')}
              </Button>
            )}
            {request.status === 'pending' && (
              <Button
                style={{marginTop: 10}}
                onClick={() => {
                  setRequest({
                    ...request,
                  });
                  const [governorate, area] = request.deliveryZone.split(' - ');
                  setGov(governorate);
                  setArea(area);
                  setEditTask(request.id);
                  setOpen(true);
                }}>
                {t('requestDriver:edit')}
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
        {expanded && request?.statusHistory && (
          <Table.Row
            style={{
              borderTopColor: 'grey',
              borderTopWidth: 0.5,
              backgroundColor: '#f7f7f7',
            }}>
            <Table.Cell colSpan={5}>
              <p>{request?.driver?.name}</p>
              <p>{request?.driver?.phone}</p>
              {request?.driver?.trackingLink && (
                <a>{request?.driver?.trackingLink}</a>
              )}
            </Table.Cell>
            <Table.Cell colSpan={6}>
              {request?.statusHistory?.map((logItem, index) => (
                <div key={logItem.timestamp} style={{marginBottom: 20}}>
                  <p>
                    {logItem.status}{' '}
                    {logItem.timestamp
                      ? `: ${moment(parseInt(logItem.timestamp)).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}`
                      : ''}
                  </p>
                  {index !== request?.statusHistory?.length - 1 && (
                    <div
                      style={{
                        borderTop: '0.6px solid #cccccc',
                        margin: '10px 0',
                      }}
                    />
                  )}
                </div>
              ))}
            </Table.Cell>
          </Table.Row>
        )}
      </>
    );
  };

  const GovDropdown = () => {
    const handleDropdownChange = (e, { value }) => {
      setGov(value);
      setArea('');
      validateForm();
    };

    return (
      <Dropdown
        placeholder={gov || "Select a governate"}
        search
        selection
        options={govDropdownOptions}
        value={gov}
        onAddItem={handleDropdownChange}
        onChange={handleDropdownChange}
      />
    );
  };

  const AreaDropdown = () => {
    const handleDropdownChange = (e, { value }) => {
      setArea(value);
      validateForm();
    };

    const areaDropdownOptions = gov ? AREAS[gov].map((key) => ({
      key,
      text: key,
      value: key,
    })) : Object.values(AREAS).flat().sort().map((key) => ({
      key,
      text: key,
      value: key,
    }));

    return (
      <Dropdown
        placeholder={area || "Select or type an area"}
        search
        selection
        allowAdditions
        options={areaDropdownOptions}
        value={area}
        onAddItem={handleDropdownChange}
        onChange={handleDropdownChange}
      />
    );
  };

  useEffect(() => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      deliveryZone: `${gov}${gov && area ? ' - ' : ''}${area}`,
    }));
    validateForm();
  }, [area, gov]);

  return (
    isInCollection ? <div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <Button className="ui button positive" style={{ width: 'fit-content' }} onClick={() => setOpen(true)}>
          {t('requestDriver:RequestADriver')}
        </Button>
        <Popup
          content="Request a driver on demand for call deliveries. When you receive orders via phone calls instead of in-app orders, you can request a driver to handle the delivery efficiently."
          trigger={
            <div>
              <Icon name="info circle" style={{ marginLeft: '0.5em', color: 'green' }} />
            </div>
          }
        />

        <label style={{ margin: 10 }}>
          {t('requestDriver:ShowOnlyActive')}:
          <input type="checkbox" checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} style={{ margin: 10 }} />
        </label>
        <Button className="ui button blue" onClick={exportTableToExcel}>
          Export to Excel
        </Button>

      </div>
        <Button className="ui button yellow" onClick={() => setRefresh(Date.now)}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <Icon name="refresh" />
            <p style={{marginInlineStart: 10}}>
              {t('requestDriver:Refresh')}
            </p>
          </div>
        </Button>
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('requestDriver:OrderNumber')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:CustomerName')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:CustomerNumber')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:Zone')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:specialNotes')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:AdditionalAddress')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:CollectCash')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:Total')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:Status')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:RequestedAt')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:CompletedAt')}</Table.HeaderCell>
            <Table.HeaderCell>{t('requestDriver:cancel')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {requests.map((request, index) => (
            <RequestRow request={request} key={request.id + index} />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={page}
        boundaryRange={3}
        onPageChange={handlePaginationChange}
        size="mini"
        siblingRange={3}
        totalPages={totalPages}
      />
      <Modal open={open} style={{ overflow: "hidden", width:"50%" }} size="large" >
        <Modal.Header>{t('requestDriver:RequestADriver')}</Modal.Header>
        <Modal.Content style={{ overflowY: 'auto' }}>
        <Checkbox
              style={{ marginLeft: 10, marginTop: 20, marginBottom: 10 }}
              label="Turn on to change the pickup location. By default it will use your store location"
              toggle
              checked={request.customPickup}
              name="customPickup"
              onChange={(e) => {
                if(!request.customPickup) {
                  // eslint-disable-next-line no-restricted-globals
                  if(confirm("Are you sure you want to select the different pickup location ?")) {
                    handleInputChange(e, {name: "customPickup", value: true})
                    return
                  }
                  return;
                }
                handleInputChange(e, {name: "customPickup", value: !request.customPickup})
              }}
            />

        {request.customPickup && (
        <div>
          <h3>Pickup Location</h3>
          <GoogleMapPicker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAhnDqtNZ54BV1nQJz0eK3hwhDsb_yMnZg`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            initialLocation={request.pickupLocation}
            onLocationUpdate={(location) => {
              handleInputChange(null, {name: "pickupLocation", value: location})
            }}
          />
        </div>
      )}
          {request.customPickup && (
              <div>
                <div style={{ marginBottom: 15, marginTop: '10px' }}>
                  <label htmlFor="latitude">Latitude:</label>
                  <input
                    type="number"
                    id="latitude"
                    name="latitude"
                    value={request.pickupLocation.latitude}
                    onChange={(e) => handleLatitudeChange(1 * e.target.value)}
                    style={{ marginLeft: '10px' }}
                  />
                </div>

                <div style={{ marginBottom: 10 }}>
                  <label htmlFor="longitude">Longitude:</label>
                  <input
                    type="number"
                    id="longitude"
                    name="longitude"
                    value={request.pickupLocation.longitude}
                    onChange={(e) => handleLongitudeChange(1 * e.target.value)}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                  <strong>PickUp Information</strong>
                  <div style={{ marginBottom: 10, marginTop:10 }}>
                  <label htmlFor="pickupName">Pickup Name:</label>
                  <input
                    type="text"
                    id="pickupName"
                    name="pickupName"
                    value={request.pickupName}
                    onChange={(e) => handleInputChange(e, { name: 'pickupName', value: e.target.value })}
                    style={{ marginLeft: '10px' }}
                  />
                  </div>
                  <div style={{marginBottom: 10}}>
                    <label htmlFor="pickupNumber">Pickup Number:</label>
                    <input
                       type="text"
                       id="pickupNumber"
                       name="pickupNumber"
                       value={request.pickupNumber}
                       onChange={(e)=> handleInputChange(e,{ name: 'pickupNumber', value: e.target.value})}
                       style={{marginLeft:'10px'}}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <label htmlFor="pickupNotes">Pickup Notes:</label>
                    <input
                      type="text"
                      id="specialNotes"
                      name="specialNotes"
                      value={request.pickupNotes}
                      onChange={(e) => handleInputChange(e, { name: 'pickupNotes', value: e.target.value })}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>

              </div>
            )}

          <hr />
          <h3>Customer Information</h3>

          {inputFields.map((field, index) => (
            <div key={index}>
              {field.required && !!errors[field.name] && <p style={{ color: 'red'}}>{errors[field.name]}</p>}
              <Input
                onBlur={validateForm}
                style={{ marginBottom: 10, width: "100%" }}
                label={field.label}
                name={field.name}
                value={field.value}
                onChange={handleInputChange}
              />
            </div>
          ))}
          <hr />
          <h3>Delivery Location Zone</h3>
          <p>{t('requestDriver:Zone')}</p>
          {!!errors['deliveryZone'] && <p style={{ color: 'red'}}>{errors['deliveryZone']}</p>}
          <GovDropdown />
          <AreaDropdown />
          <div>
            <Input
              onBlur={validateForm}
              style={{ marginBottom: 10, marginTop: 10,  width: '100%'}}
              label={t('requestDriver:AdditionalAddress')}
              name={'additionalAddressDetail'}
              value={request.additionalAddressDetail}
              onChange={handleInputChange}
            />
          </div>
          <hr />

          <Checkbox
            style={{ marginLeft: 10, marginTop: 20, marginBottom: 10, }}
            label={t('requestDriver:CollectCash')}
            checked={request.collectCash}
            onChange={handleToggleChange}
          />
          {request.collectCash && (
            <Input
              style={{ marginBottom: 10, }}
              label={t('requestDriver:Total')}
              name="totalAmount"
              value={request.totalAmount}
              onChange={handleInputChange}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel} color="red" disabled={sendButtonDisabled}>
            {t("cancel")}
          </Button>

          <Button onClick={onSend} color="green" disabled={sendButtonDisabled || unresolvedErrors}>
            {t("send")}
          </Button>
        </Modal.Actions>
      </Modal>
    </div> : <div>Unauthorized</div>
  );
};

export default RequestDriver;
