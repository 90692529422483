import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch,withRouter } from "react-router-dom";
import ReactNotification from "react-notifications-component";
//routes
import {
  HOME,
  SIGN_IN,
  ORDER_HISTORY,
  ORDER_DETAILS,
  STORE_MENU,
  STATS,
  KMARTITEMS,
  KMARTORDERS,
  PRODUCT_MANAGEMENT,
  TRACK_EDITS,
  REQUEST_DRIVER, REQUEST_DRIVER_v2
} from "../../constants/routes";
import Login from "../../page/Login";

//others
import Home from "../../page/Home";
import Orders from "../../page/orders";
import OrderDetails from "../../page/OrderDetails";
import productManagement from "../../page/ProductManagement";
import StoreMenu from "../../page/StoreMenu";
import Stats from "../../page/Stats";
import KmartOrders from "../../components/Kmart/orders/index";
import KmartItems from "../../components/Kmart/items";

import PrivateRoute from "../PrivateRoute";
import { MainLayout, KmartLayout } from "../../layouts";
import { Provider as BrowserNotificationsProvider } from "../../context/notifications";
import { Provider as OrderManagementProvider } from "../../context/orderManagement";
import { Provider as AppSettingsProvider } from "../../context/appSettings";
import { Provider as KmartProvider } from "../../context/kmartcontext";
import i18next from "i18next";
import UpdateNotication from "../../context/updateNotication";
import TrackEdits from "../../page/TrackEdits";
import RequestDriver from "../../page/RequestDriver";
import RequestDriverV2 from "../../page/RequestDriver/requestDriverV2";

const App = (props) => {
  const [vendorLogin, setVendorLogin] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const html = document.querySelector("html");
    html.dir = i18next.dir();
    html.lang = i18next.language;
  }, []);

  useEffect(() => {
    const listener = props.firebase
      .auth()
      .onAuthStateChanged(async (authUser) => {
        if (!authUser) return;
        const user = await authUser.getIdTokenResult();
        setVendorLogin(user.claims.role === "kmartvendor");
      });

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetActiveIndex = (index) => {
    setActiveIndex(index);
  };


  return (
    <UpdateNotication client={props.client}>
      <BrowserNotificationsProvider client={props.client}>
        {!vendorLogin && vendorLogin !== null && (<>
        <ReactNotification />
        <OrderManagementProvider>
          <AppSettingsProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path={HOME} component={Home} layout={MainLayout} />
                <PrivateRoute
                      exact
                      path={ORDER_HISTORY}
                      component={withRouter((props) => (
                        <Orders {...props} activeIndex={activeIndex} setActiveIndex={handleSetActiveIndex} />
                      ))}
                      layout={MainLayout}
                    />
                <PrivateRoute exact path={ORDER_DETAILS} component={OrderDetails} layout={MainLayout} />
                <PrivateRoute exact path={STORE_MENU} component={StoreMenu} layout={MainLayout} />
                <PrivateRoute exact path={STATS} component={Stats} layout={MainLayout} />
                <PrivateRoute exact path={TRACK_EDITS} component={TrackEdits} layout={MainLayout} />
                <PrivateRoute exact path={REQUEST_DRIVER} component={RequestDriver} layout={MainLayout} />
                <PrivateRoute exact path={REQUEST_DRIVER_v2} component={RequestDriverV2} layout={MainLayout} />
                <PrivateRoute exact path={PRODUCT_MANAGEMENT} component={productManagement} layout={MainLayout} />

              </Switch>
            </Router>
          </AppSettingsProvider>
        </OrderManagementProvider>
        </>
        )}
        {vendorLogin && vendorLogin !== null && (
          <AppSettingsProvider>

          <KmartProvider>
            <Router>
              <Switch>
                <PrivateRoute
                  exact
                  path={HOME}
                  component={KmartOrders}
                  layout={KmartLayout}
                  />
                <PrivateRoute
                  exact
                  path={KMARTITEMS}
                  component={KmartItems}
                  layout={KmartLayout}
                />
                <PrivateRoute exact path={REQUEST_DRIVER} component={RequestDriver} layout={KmartLayout} />
                <PrivateRoute exact path={REQUEST_DRIVER_v2} component={RequestDriver} layout={KmartLayout} />
              </Switch>
            </Router>
          </KmartProvider>
                  </AppSettingsProvider>
        )}
        <Router>
          <Route exact path={SIGN_IN} component={Login} />
          <Route exact path={HOME} component={Login} />
        </Router>
      </BrowserNotificationsProvider>
    </UpdateNotication>
  );
};

export default App;
